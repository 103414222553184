//CRUDS
import CompanyForm from '../pages/Company/form';
import CompanyIndex from '../pages/Company/index';
import CurrencyTypeForm from '../pages/CurrencyType/form';
import CurrencyTypeIndex from '../pages/CurrencyType/index';
import Dashboard from '../pages/Dashboard';
import GroupForm from '../pages/Group/form';
import GroupIndex from '../pages/Group/index';
import KPIForm from '../pages/KPI/form';
import KPIIndex from '../pages/KPI/index';
import MailNotificationDeadlineFormIndex from '../pages/MailNotificationDeadlineForm';
import ModuleForm from '../pages/Module/form';
import ModuleIndex from '../pages/Module/index';
import OperationForm from '../pages/Operation/form';
import OperationIndex from '../pages/Operation/index';
import ReportYearForm from '../pages/ReportYear/form';
import ReportYearIndex from '../pages/ReportYear/index';
import SectionForm from '../pages/Section/form';
import SectionIndex from '../pages/Section/index';
import SubSectionForm from '../pages/SubSection/form';
import SubSectionIndex from '../pages/SubSection/index';
import SubSectionKPIEdit from '../pages/SubSectionKPI/edit';
import SubSectionKPIForm from '../pages/SubSectionKPI/form';
import SubSectionKPIIndex from '../pages/SubSectionKPI/index';
import TobaccoTypeForm from '../pages/TobaccoType/form';
import TobaccoTypeIndex from '../pages/TobaccoType/index';
import ESGSubjectForm from '../pages/ESGSubject/form';
import ESGSubjectIndex from '../pages/ESGSubject/index';
import UnitMeasureForm from '../pages/UnitMeasure/form';
import UnitMeasureIndex from '../pages/UnitMeasure/index';
import UserForm from '../pages/User/form';
import UserIndex from '../pages/User/index';


import MigrateDashboard from '../pages/MigrateDashboard/index';

//Other Pages
import AdditionalDataDeadlineFormIndex from '../pages/AdditionalDataDeadlineForm';
import AdditionalDataDefaultValuesForm from '../pages/AdditionalDataDefaultValuesForm';
import DiscussionDashboard from '../pages/DiscussionsDashboard/index';
import IndexFolder from '../pages/DocumentUpload/IndexFolder';
import Export from '../pages/Export/index';

import Report from '../pages/Report/index';
import SignIn from '../pages/SignIn';
import ForgotPassword from '../pages/SignIn/forgotPassword';
import MFASignIn from '../pages/SignIn/mfaSignIn';
import VersionComparison from '../pages/VersionComparison/index';
import ReopenModule from '../pages/ReopenModule';
import AdditionalDataTargetsFormIndex from '../pages/AdditionalDataTargetsForm';


const ROLE_ADMIN = "RoleAdmin";
const ROLE_INFO_HOLDER = "RoleInfoHolder";
const ROLE_APPROVER = "RoleApprover";
const ROLE_READ_ONLY = "RoleReadOnly";
const ROLE_ESGNANAGER = "RoleESGManager";
const ROLE_ESGAPPROVER = "RoleESGApprover";
const ROLE_ESGPERFORMANCEMANAGER = "RoleESGPerformanceManager";

const RoutesList = [
    {
        path: "/signin",
        label: "SignIn",
        component: SignIn,
        onlyGlobal: false
    },
    {
        path: "/Report/:operation/:moduleName/:waiverIdentifier?",
        label: "Report",
        component: Report,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN, ROLE_APPROVER, ROLE_INFO_HOLDER, ROLE_READ_ONLY, ROLE_ESGAPPROVER, ROLE_ESGNANAGER, ROLE_ESGPERFORMANCEMANAGER],
        showInSidebar: false,
        onlyGlobal: false
    },
    {
        path: "/signin/MultiFactorAuthentication",
        label: "MFASignIn",
        component: MFASignIn,
        onlyGlobal: false
    },
    {
        path: "/ForgotPassword/Token=:token&Id=:id",
        label: "Forgot Password",
        component: ForgotPassword,
        onlyGlobal: false
    },
    {
        path: "/populate",
        label: "SignIn",
        component: SignIn,
        onlyGlobal: false
    },
    {
        path: "/",
        label: "Home",
        component: Dashboard,
        isPrivate: true,
        showInSidebar: true,
        onlyGlobal: false
    },    
    {
        path: "/Documents",
        label: "Documents Folder",
        component: IndexFolder,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN, ROLE_APPROVER, ROLE_INFO_HOLDER, ROLE_READ_ONLY, ROLE_ESGAPPROVER, ROLE_ESGNANAGER, ROLE_ESGPERFORMANCEMANAGER],
        showInSidebar: true,
        onlyGlobal: false,
        childrens: [
            {
                path: "/Documents/id=:documentFolderID",
                label: "Documents Folder",
                component: IndexFolder,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN, ROLE_APPROVER, ROLE_INFO_HOLDER, ROLE_READ_ONLY, ROLE_ESGAPPROVER, ROLE_ESGNANAGER, ROLE_ESGPERFORMANCEMANAGER],
                showInSidebar: false,
                onlyGlobal: false
            }
        ]
    },
    {
        path: "/discussionDashboard",
        label: "Discussion Dashboard",
        component: DiscussionDashboard,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN, ROLE_APPROVER, ROLE_INFO_HOLDER, ROLE_READ_ONLY, ROLE_ESGAPPROVER, ROLE_ESGNANAGER, ROLE_ESGPERFORMANCEMANAGER],
        showInSidebar: true,
        onlyGlobal: false,
        childrens: [
            {
                path: "/discussionDashboard/companyID=:companyID&reportYearID=:reportYearID&operationID=:operationID&moduleID=:moduleID",
                label: "Discussion Dashboard",
                component: DiscussionDashboard,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN, ROLE_APPROVER, ROLE_INFO_HOLDER, ROLE_READ_ONLY, ROLE_ESGAPPROVER, ROLE_ESGNANAGER, ROLE_ESGPERFORMANCEMANAGER],
                showInSidebar: false,
                onlyGlobal: false
            }
        ]
    },
    {
        path: "/compareVersion",
        label: "Version Comparison",
        component: VersionComparison,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN, ROLE_APPROVER, ROLE_INFO_HOLDER, ROLE_READ_ONLY, ROLE_ESGAPPROVER, ROLE_ESGNANAGER, ROLE_ESGPERFORMANCEMANAGER],
        showInSidebar: true,
        onlyGlobal: false,
        childrens: [
            {
                path: "/compareVersion/companyID=:companyID&reportYearID=:reportYearID&operationID=:operationID&moduleID=:moduleID",
                label: "Version Comparison",
                component: VersionComparison,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN, ROLE_APPROVER, ROLE_INFO_HOLDER, ROLE_READ_ONLY, ROLE_ESGAPPROVER, ROLE_ESGNANAGER, ROLE_ESGPERFORMANCEMANAGER],
                showInSidebar: false,
                onlyGlobal: false
            }
        ]
    },
    {
        label: "Exports",
        isMenu: true,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN, ROLE_APPROVER, ROLE_INFO_HOLDER, ROLE_READ_ONLY, ROLE_ESGAPPROVER, ROLE_ESGNANAGER, ROLE_ESGPERFORMANCEMANAGER],
        showInSidebar: true,
        onlyGlobal: false,
        childrens: [
            {
                path: "/export",
                label: "Masterfile",
                component: Export,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN, ROLE_APPROVER, ROLE_INFO_HOLDER, ROLE_READ_ONLY, ROLE_ESGAPPROVER, ROLE_ESGNANAGER, ROLE_ESGPERFORMANCEMANAGER],
                showInSidebar: true
            },            
        ]

    },
   
    {
        label: "Registers",
        isMenu: true,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        showInSidebar: true,
        onlyGlobal: false,
        childrens: [
            {
                path: "/operation",
                label: "Form",
                component: OperationIndex,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                showInSidebar: true,
                onlyGlobal: true
            },
            {
                path: "/operation/edit/:id",
                label: "Edit Form",
                component: OperationForm,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                onlyGlobal: true
            },
            {
                path: "/operation/new",
                label: "New Form",
                component: OperationForm,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                onlyGlobal: true
            },
            {
                path: "/module",
                label: "Module",
                component: ModuleIndex,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                showInSidebar: true,
                onlyGlobal: true
            },
            {
                path: "/module/edit/:id",
                label: "Edit Module",
                component: ModuleForm,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                onlyGlobal: true
            },
            {
                path: "/module/new",
                label: "New Module",
                component: ModuleForm,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                onlyGlobal: true
            },
            {
                path: "/section",
                label: "Section",
                component: SectionIndex,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                showInSidebar: true,
                onlyGlobal: true
            },
            {
                path: "/section/edit/:id",
                label: "Edit Section",
                component: SectionForm,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                onlyGlobal: true
            },
            {
                path: "/section/new",
                label: "New Section",
                component: SectionForm,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                onlyGlobal: true
            },
            {
                path: "/subSection",
                label: "Sub Section",
                component: SubSectionIndex,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                showInSidebar: true,
                onlyGlobal: true
            },
            {
                path: "/subSection/edit/:id",
                label: "Edit Subsection",
                component: SubSectionForm,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                onlyGlobal: true
            },
            {
                path: "/subSection/new",
                label: "New Subsection",
                component: SubSectionForm,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                onlyGlobal: true
            },
            {
                path: "/kpi",
                label: "KPI",
                component: KPIIndex,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                showInSidebar: true,
                onlyGlobal: true
            },
            {
                path: "/kpi/edit/:id",
                label: "KPI",
                component: KPIForm,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                onlyGlobal: true
            },
            {
                path: "/kpi/new",
                label: "KPI",
                component: KPIForm,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                onlyGlobal: true
            },
            {
                path: "/subSectionKPI",
                label: "Sub Section KPI",
                component: SubSectionKPIIndex,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                showInSidebar: true,
                onlyGlobal: true
            },
            {
                path: "/subSectionKPI/edit/:id",
                label: "Edit Sub Section KPI",
                component: SubSectionKPIEdit,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                onlyGlobal: true
            },
            {
                path: "/subSectionKPI/new",
                label: "New Subsection KPI",
                component: SubSectionKPIForm,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                onlyGlobal: true
            },
            {
                path: "/answerTypeKPI",
                label: "Answer Type KPI",
                component: UnitMeasureIndex,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                showInSidebar: true,
                onlyGlobal: true
            },
            {
                path: "/answerTypeKPI/edit/:id",
                label: "Edit Answer Type KPI",
                component: UnitMeasureForm,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                onlyGlobal: true
            },
            {
                path: "/answerTypeKPI/new",
                label: "New Answer Type KPI",
                component: UnitMeasureForm,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                onlyGlobal: true
            },
            {
                path: "/tobaccoType",
                label: "Tobacco Type",
                component: TobaccoTypeIndex,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                showInSidebar: true,
                onlyGlobal: true
            },
            {
                path: "/tobaccoType/edit/:id",
                label: "Edit Tobacco Type",
                component: TobaccoTypeForm,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                onlyGlobal: true
            },
            {
                path: "/tobaccoType/new",
                label: "New Tobacco Type",
                component: TobaccoTypeForm,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                onlyGlobal: true
            },
            {
                path: "/ESGSubject",
                label: "ESG Subject",
                component: ESGSubjectIndex,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                showInSidebar: true,
                onlyGlobal: true
            },
            {
                path: "/ESGSubject/edit/:id",
                label: "Edit ESGSubject",
                component: ESGSubjectForm,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                onlyGlobal: true
            },
            {
                path: "/ESGSubject/new",
                label: "New ESG Subject",
                component: ESGSubjectForm,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                onlyGlobal: true
            },
            {
                path: "/reportYear",
                label: "Report Year",
                component: ReportYearIndex,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                showInSidebar: true,
                onlyGlobal: true
            },
            {
                path: "/reportYear/edit/:id",
                label: "Edit Report Year",
                component: ReportYearForm,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                onlyGlobal: true
            },
            {
                path: "/reportYear/new",
                label: "New Report Year",
                component: ReportYearForm,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                onlyGlobal: true
            },           
            {
                path: "/migrate",
                label: "Migrate Structure",
                component: MigrateDashboard,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                showInSidebar: true,
                onlyGlobal: true
            },            
            {
                path: "/notificationDeadlines",
                label: "Email Notification Deadlines",
                component: MailNotificationDeadlineFormIndex,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                showInSidebar: true,
                onlyGlobal: true
            },            
        ]
    },
    {
        label: "Suppliers - Registers",
        isMenu: true,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        showInSidebar: true,
        childrens: [
            {
                path: "/company",
                label: "Supplier",
                component: CompanyIndex,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                showInSidebar: true,
                onlyGlobal: true
            },
            {
                path: "/company/edit/:id",
                label: "Edit Supplier",
                component: CompanyForm,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                onlyGlobal: true
            },
            {
                path: "/company/new",
                label: "New Supplier",
                component: CompanyForm,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                onlyGlobal: true
            },
            {
                path: "/currencyType",
                label: "Currency Type",
                component: CurrencyTypeIndex,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                showInSidebar: true,
                onlyGlobal: true
            },
            {
                path: "/currencyType/edit/:id",
                label: "Edit Currency Type",
                component: CurrencyTypeForm,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                onlyGlobal: true
            },
            {
                path: "/currencyType/new",
                label: "New Currency Type",
                component: CurrencyTypeForm,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                onlyGlobal: true
            },
            {
                path: "/group",
                label: "Group",
                component: GroupIndex,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                showInSidebar: true,
                onlyGlobal: true
            },
            {
                path: "/group/edit/:id",
                label: "Edit Group",
                component: GroupForm,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                onlyGlobal: true
            },
            {
                path: "/group/new",
                label: "New Group",
                component: GroupForm,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                onlyGlobal: true
            },
        ]
    },
    {
        label: "Users - Registers and Logs",
        isMenu: true,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        showInSidebar: true,
        childrens: [
            {
                path: "/user",
                label: "User",
                component: UserIndex,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                showInSidebar: true,
                onlyGlobal: true
            },
            {
                path: "/user/new",
                label: "New User",
                component: UserForm,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                onlyGlobal: true
            },
            {
                path: "/user/edit/:id",
                label: "Edit User",
                component: UserForm,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                onlyGlobal: true
            },            
        ]
    },
    {
        label: "Additional Data",
        isMenu: true,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        showInSidebar: true,
        childrens: [
            
            {
                path: "/additionalDataDeadline",
                label: "Additional Data - Deadline",
                component: AdditionalDataDeadlineFormIndex,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                showInSidebar: true,
                onlyGlobal: true
            },            
            {
                path: "/additionalDataTargets",
                label: "Additional Data - Targets",
                component: AdditionalDataTargetsFormIndex,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                showInSidebar: true,
                onlyGlobal: true
            },            
            {
                path: "/subSectionKPI/defaultValues",
                label: "Default Values",
                component: AdditionalDataDefaultValuesForm,
                isPrivate: true,
                allowedRoles: [ROLE_ADMIN],
                showInSidebar: true,
                onlyGlobal: true
            },
        ]
    }    
];

export default RoutesList;
